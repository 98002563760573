
import { defineComponent } from "vue";
import ManagerPlanJournal from "@/components/journals/PlanJournal/ManagerPlanJournal.vue";

export default defineComponent({
  components: { ManagerPlanJournal },
  props: {},
  methods: {},
  data() {
    return {};
  },
});
